:root{
  --azul-europool: #0368B1;

  --border-radius-botones: 8px;
  --border-radius-container: 24px;
  --font-family: sans-serif;
  --fondo-gris: #f2f2f2;
  --gris-claro:#eeeeee;
  --rojo-alerta: #d32e2e;
  --amarillo-tarjeta-gojs: #FFC700;
  --verde-tarjeta-gojs: #092806;
  --rojo-tarjeta-gojs: #FF0000;

  --borde-separacion-gris: #00000033;
  --texto-gris: #535353;

  --alerta-rojo: #FF0000;
  --alerta-amarillo: #C9C56D;

}


/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

nav{
    display: flex;
    padding: 1rem 0;
    /* justify-content: center; */
}
nav a {
  text-decoration: none;
  color: var(--azul-europool);
  border: 1px solid var(--azul-europool);
  border-radius: var(--border-radius-botones);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  margin-right: .5rem;

} 
nav a span >img{
  padding-right: 8px;

}

nav a.seleccionado{
  text-decoration: none;
  color: white;
  border: 1px solid var(--azul-europool);
  border-radius: var(--border-radius-botones);
  background-color: var(--azul-europool) !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;

} 
nav a.seleccionado span > img{
  filter: invert(100%) sepia(99%) saturate(3%) hue-rotate(115deg) brightness(109%) contrast(100%);
}
/*nav ul{
  display: flex;
  list-style: none;
}
nav ul li{
  padding: 0px 1rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 3px;
}
nav ul li.seleccionado a{
  color: white;
}
nav ul li a{
  color: #aaa;
} */


/******************/
/***** LAYOUT *****/
/******************/
body{
  font-family: 'Inter';
  font-family: var(--font-family);
}
.container-pagina{
  display: flex;
  background-color: var(--fondo-gris);
  /* padding: 2rem; */
  min-height: 100vh;
}

aside{
  background: white;
  border-radius: var(--border-radius-container);
  box-shadow: 4px 4px 6px 4px rgba(0, 255, 255, 0.05);
  flex-basis: 25%;
  padding: 2rem;
  margin: 2rem;
  height: fit-content;
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.5s;
  overflow: hidden;
}
aside input{
  cursor: pointer;
}
main{
  padding: 2rem;
  margin: 0rem;
  width: 100%;
overflow: hidden;
}
.aside__logo{
  display: flex;
  width: 197px; /* Iguala el tamaño al nav para poder centrar el select de área de analisis */
  cursor: pointer;
}
.aside__logo img{
  width: 150px;
}

h2{
  color: var(--azul-europool);
  font-weight: 400;
  text-align: left;
  font-size: 20px;
}

h3{
  color: var(--azul-europool);
  font-weight: 200;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
}

/***************/
/**** ASIDE ****/
/***************/

.aside_datos_extra{
  margin-top: 1.5rem; 
  
}

.aside_datos_extra .stock_total h2{
  font-weight: bold;
}

.aside_datos_extra a{
  text-decoration: none;
}

.aside_datos_extra p{
  font-size: 17px;
  color: #000000
}

.aside_datos_extra .stock_total p{
  font-weight: bold;
}

.aside_datos_extra .platafomas_tiendas{
  display: flex;
}

.aside_datos_extra .platafomas_tiendas >div:first-child{
  margin-right: 30px;
}

.aside-flujos__tabla-stock-acumulado, .aside-flujos__filtros{
  padding:0rem 0;
}
.aside__logout{
  border-top: 1px solid var(--borde-separacion-gris);
  padding: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 0rem;
  margin: 0 -1rem;
}
.aside__logout > div{
  display: flex;
  /* align-items: center; */
  cursor: pointer;
}
.aside__logout > div img{
  padding-right: 0.5rem;
}
.aside__logout > div span{
  color: var(--texto-gris);
  font-size: 14px;
}
.aside__logout > div:hover span{
  color: var(--azul-europool);
}
.aside__logout > div:hover img{
  filter: invert(25%) sepia(88%) saturate(1749%) hue-rotate(186deg) brightness(93%) contrast(98%);
}

/**** ASIDE: FILTROS DE FLUJOS  ****/

.aside-flujos__tabla-stock-acumulado td,
  .aside-flujos__tabla-stock-acumulado th{
  padding: 8px 5px;
}
.aside-flujos__tabla-stock-acumulado th:first-of-type{
  width: 30%;
  }
.aside-flujos__tabla-stock-acumulado table{
  border-collapse: collapse;
  border-color: #0368b179;
}
.aside-flujos__tabla-stock-acumulado table tr:not(:last-of-type){
  border-bottom: .25px solid #0368b179;
}
.aside-flujos__filtros__fechas{
  display: inline-flex;
  justify-content: start;
  margin-bottom: 1rem;
  flex-direction: column;
  width: 100%;
}

.aside-flujos__filtros__fechas > div{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.aside-flujos__filtros__fechas > div > label{
  padding: .5rem 0px;
  color: var(--texto-gris);
  /*border-right: 1px solid #ccc;*/
  font-size: 13px;
  width: 40px;
}

.aside-flujos__filtros__fechas > div:last-of-type > label{
  /*border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;*/
  
}
.aside-flujos__filtros__fechas > div > input{
  /* font-size: 15px; */
  border: none;
  font-family: var(--font-family);
  max-width: 180px;
  cursor: pointer;
  outline: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px 6px;
  width: 100%;
}
.aside-flujos__filtros__flujos{
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;

}
.aside-flujos__filtros__flujos > div{
  display: flex;
  flex-direction: column;
  align-items: self-start;
  width: 150px;
}
.aside-flujos__filtros__flujos > div > label{
  display: flex;
  flex-direction: row-reverse;
  margin-left: 1rem;
  margin-bottom: .25rem;
  font-size: 13px;
  cursor: pointer;
}
.aside-flujos__filtros__flujos > div > label:first-of-type{
text-transform: uppercase;
margin-left: 0;
}

.aside-flujos__filtros__procesos{
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  justify-content: space-around;
  font-size: 13px;
}
.aside-flujos__filtros__procesos > label{
margin-bottom: 5px;
font-weight: 500;
cursor: pointer;
}


/**** ASIDE: FILTROS DE ANALISIS  ****/
.aside-analisis__filtros{
  padding: 1rem 0;
}
.aside-analisis__filtros__area-analisis label{
  /*width: 100%;*/
  /*display: inline-block;*/
  display: block;
  font-size: 15px;
}
.aside-analisis__filtros__area-analisis select{
padding: .25rem;
border-radius: 4px;
margin-top: .5rem;
}

/**** FIN ASIDE: FILTROS DE ANALISIS  ****/


/************/
.diagram-component {
  height: calc(100vh - 50px);
  width: 100%;
  /* background-color: rgb(255, 255, 255); */
  margin-left: auto;
  margin-right: auto;
}



/******* tableau ************/
div.myTableauViz {
  width: 1650px;
  margin-left : auto;
  margin-right: auto;
}
.tab-content {
  background: rgb(254, 254, 254);
  line-height: 25px;
  border-top: 5px solid #006950;
  border-bottom: 5px solid #006950;
  border-left: none;
  border-right: none;
  padding: 30px 25%; /* reverted back to 25% */
/* update 2 ingnore: changed 25% to 0, top/bottom padding only */
}

.tableauPlaceholder{
    width: 80%;
    height: 800px;
    align-content: center;
    margin-left: auto;
}

.tableauViz{
    width: 80%;
    height: 1500px;
}

.vizContainer{
  background-color: white;
  border-radius: var(--border-radius-container);
  display: flex;
  justify-content: center;
  /*padding: 2rem 0;*/
  overflow: hidden;
  box-shadow: 4px 4px 6px 4px rgba(0, 255, 255, 0.05);
  /* width: 100%;
   height: 1000px; */
   width: 97%;
    height: calc(100vh - 150px);
    /* position: relative; overflow: hidden; display: block; */
}
.tb-container{
  border: none;
}
/******* FIN: tableau ************/

/******* LOGIN *******/
.login__page{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--fondo-gris);
  height: 100vh;
  
}
.login__container {
  background-color: white;
  flex-direction: column;
  border-radius: var(--border-radius-container);
  box-shadow: 4px 4px 6px 4px rgba(0, 255, 255, 0.05);
  width: 370px;
  height: fit-content;
  padding: 6rem 2rem;

}
.login__logo,.login__titulo{
  display: flex;
  justify-content: center;
}
.login__logo img{
  max-width: 180px;
}
.login__titulo{
  padding-bottom: 2rem;
  padding-top: 0.5rem;
}
.login__titulo label{
  font-weight: 600;
}
.login__inputs > div{
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
}
.login__inputs > div label{
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 6px;
}
.login__inputs > div input{
  font-size: 15px;
  padding: 0.5rem;
  border-radius: 4px;
  border-width: 1px;
  border-color: var(--azul-europool);

}
.login__inputs > .login__inputs-error  input{
  border: 2px solid var(--rojo-alerta)
}
.login__iniciar-sesion{
  padding: 1rem 0;
}
.login__iniciar-sesion > button{
  background: var(--azul-europool);
  color: white;
  width: 100%;
  padding: .6rem;
  border-radius: 4px;
  border-width: 0;
  text-transform: uppercase;
  cursor: pointer;
}
.login__iniciar-sesion > button:disabled {
  opacity: .5;
}

.vpo_logo{
  display: flex;
  justify-content: center;
  margin-top: 52px;
}

.vpo_logo a{
  font-weight: 400;
font-size: 17px;
color: rgba(0, 0, 0, 0.2) !important;
text-decoration: none;
display: flex;
justify-content: center;
align-items: center;
transition: 0.3s;
}

.vpo_logo a:hover{
  opacity: 0.5;
}

.vpo_logo img{
  width: 57px;
  margin-left: 13px;
}

  /******* FIN: LOGIN *******/

.mensaje-sin-filtros{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/***************** Leyenda flujos *****************/

.aside-leyenda .leyenda{
  display: flex;
  border-radius: 8px;
  margin-right: 10px
}

.aside-leyenda .leyenda span{
  width: 18px;
  margin: 0px;
  margin-right: 5px !important;
  height: 6px;
  margin-right: 10px;
}

.aside-leyenda .leyenda-frescos span{
  background-color: #146F8F;
}

.aside-leyenda .leyenda-seco span{
  background-color: #FFA545;
}

.aside-leyenda .leyenda span:first-child {
  border-radius: 10px 0px 0px 10px;
  width: 12px;
}

.aside-leyenda .leyenda span:last-child {
  border-radius: 0px 10px 10px 0px;
  width: 12px;
}

.aside-leyenda > div{
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 13px;
}

/**************** Layout Analisis ****************/

.container-analisis{
  flex-direction: column;
}
.container-analisis .aside_datos_extra{
  display: none;

}

.container-analisis .aside__logout{
  display: none;

}

.container-analisis > aside {
  flex-basis: auto;
  padding: 0;
}
.container-analisis > aside > div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.1rem 2rem;

}


.container-analisis > aside > div .aside-analisis__filtros{
order: 2;
}

.container-analisis > aside .aside-analisis__filtros label{
font-weight: 400;
  color: #828282;
}

.container-analisis > aside .aside-analisis__filtros select{
  width: 130px;
  background: #FFFFFF;
  border: 0.5px solid #0368B1;
  border-radius: 4px;
}

.container-analisis > aside > div > nav{
  flex-basis: auto;
display: flex;
justify-content: space-between;
order: 3;
}

.container-analisis > aside > nav a{
  height: fit-content;
  
}

.container-analisis main, .container-analisis .vizContainer{
  width: auto;
}

.container-analisis main{
  padding-top: 0;
}

.container-analisis .vizContainer{
  height: calc(100vh - 181px);
}

/* Large devices (laptops/desktops) */
@media only screen and (max-width: 1450px) {
  
  .aside-analisis__filtros{
    padding: 0px !important ;
  }

  .aside-analisis__filtros__area-analisis select{
    margin-top: 0px !important;
  }

  .aside-analisis__filtros__area-analisis label{
    font-size: 12px !important;
  }


  .container-analisis aside nav{
    padding: 0px !important;
  }

}

/**************** Layout Flujos ****************/
.main-flujos__diagrama-gojs{
  display: flex;
}

.container-flujos{
  height: 100vh;
}

.container-flujos aside nav {
  position: absolute;
  top: 3.6rem;
  right: 4rem;
  z-index: 3;
  padding: 0;
}

.container-flujos aside{
  max-width: 15rem;
  height: -moz-fit-content;
  height: auto;
  max-height: 100vh;
}

.container-flujos nav a{
  background-color: white;
}

/* Efecto hover nav a */

nav a::after{
  content: "";
  width: 101%;
  height: 101%;
  position: absolute;
  top: 0;
  bottom: 0;
  transform: scaleY(0);
  background-color: var(--azul-europool);
  z-index: 0;
  transform-origin: top;
  transition: 0.3s;
  border-radius: 0px 0px var(--border-radius-botones) var(--border-radius-botones);
}

nav a:hover span  {
  
color: white;
  
}

nav a:hover span img {
  
filter: invert(100%) sepia(99%) saturate(3%) hue-rotate(115deg) brightness(109%) contrast(100%);
  
}

nav a:hover:after{
  
  transform: scaleY(1);
  
}
nav a{
  position: relative;
  overflow: hidden;
}
nav a span{ 
display: flex;
  justify-content: center;
  z-index: 1;
}

/*     Aside desplegable     */

.aside-plegado{
  padding: 12px;
  max-height: 59px !important;
  max-width: 50px !important;
}

.aside-plegado .aside__logo img {
  height: 59.42px;
  width: auto;
}

/**** ASIDE: DETALLE INFO DE NODOS  ****/
.detalle-nodo{
  position: absolute;
  top: 0;
  right: 0;
  width: 0px;

  transform: translateX(100%);
  transition:  transform .2s ease-out, width .2s ease-out;

  height: 100vh;
  z-index: 100;
  background: white;
}
.detalle-nodo-clickado{
  width: 370px;

  /* box-shadow: 0px 0px 0 100vmax #6868684a; */
  /* animation: .3s ease-out  0s 1 slideInFromLeft; */
  transition:  transform .3s ease-out ; 
  transform: translateX(0%); 
}

.detalle-nodo-clickado__titulo{
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  border-bottom: 1px solid var(--borde-separacion-gris);
}
.detalle-nodo-clickado > div{
  padding: 1rem 0;
}
.detalle-nodo-clickado__titulo >div{
  display: flex;
  align-items: center;
}
.detalle-nodo-clickado__titulo >div >img{
  height: 22px;
  padding-right: 0.75rem;
}
.detalle-nodo-clickado__titulo >div label{
  font-weight: 600;
}
.detalle-nodo-clickado__close{
  cursor: pointer;
}
.detalle-nodo-clickado__info{
  padding: 1rem 2rem;
}
.detalle-nodo-clickado__info > div{
  border-bottom: 1px solid var(--borde-separacion-gris);
  padding-bottom: 0.25rem;
  padding-top: 1rem;
  margin-left: -0.5rem;
  display: flex;
}
.detalle-nodo-clickado__info > div > label{
  padding-left: 0.5rem;
  /* font-weight: 300; */
  width: 70%;
  display: inline-block;
  color: var(--texto-gris);
}
.detalle-nodo-clickado__info > div > span{
  font-weight: 600;
  margin-left: auto;
  padding-right: 0.5rem;
  white-space:nowrap;
}

.detalle-nodo-clickado__info .linkDetalle{
  border-bottom: none;
  margin-top: 32px;
}

.detalle-nodo-clickado__info .linkDetalle a{
  display: flex;
  text-decoration: none;
  color: #535353;
  font-size: 16px;
}

.detalle-nodo-clickado__info .linkDetalle a{
  display: flex;
  text-decoration: none;
  color: #535353;
  font-size: 16px;
  transition: 0.3;
}

.detalle-nodo-clickado__info .linkDetalle a:hover{
  color: var(--azul-europool)
}

.detalle-nodo-clickado__info .linkDetalle a:hover img{
  filter: invert(56%) sepia(99%) saturate(4603%) hue-rotate(162deg) brightness(70%) contrast(301%);
}

.detalle-nodo-clickado__info .linkDetalle img{
  height: 15px;
  padding: 0 0.5rem;
}

.detalle-nodo-clickado__content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 1rem - 87px);
}

.detalle-nodo-clickado__alertas{
  padding: 2rem;
}

.detalle-nodo-clickado__alertas p{
  display: flex;
  align-content: center;
}

.detalle-nodo-clickado__alertas h4{
  font-weight: 16px;
}

.detalle-nodo-clickado__alertas p label{
  height: 16px;
  width: 16px;
  display: inline-block;
  margin-right: 15px;
}

.detalle-nodo-clickado__alertas p:last-child label{
  border: 1px solid #C9C56D;
}

.detalle-nodo-clickado__alertas .alertas-stock p label{
  border: 1px solid var(--alerta-rojo);
  border-radius: 4px;
}

.detalle-nodo-clickado__alertas .alertas-stock p:last-child label{
  border: 1px solid var(--alerta-amarillo);
}

.detalle-nodo-clickado__alertas .alertas-tiendas p label{
  background-color: var(--alerta-rojo);
  border: 1px solid var(--alerta-rojo);
  border-radius: 100%;
}

.detalle-nodo-clickado__alertas .alertas-tiendas p:last-child label{
  background-color: var(--alerta-amarillo);
  border: 1px solid var(--alerta-amarillo);
}

/* estilo pantallas pequeñas */

@media (max-height: 767px) {
  .container-flujos aside {
    max-width: 11rem;
  }

  .aside-flujos__tabla-stock-acumulado, .aside-flujos__filtros{
    padding: 0rem 0;
  }
  
  .aside_datos_extra{
    margin-top: 1rem;
  }

  aside *, .detalle-nodo * { 
    font-size: 11px !important;
  }

  aside h2{
    font-size: 16px !important;
    margin-bottom: 5px;
  }

  aside nav img{
    width: 14px;
  }

  aside nav span{
    display: flex;
    align-items: center;
  }

  aside .aside__logo img{
    height: 32px;
    width: auto !important;
  }

  aside .leyenda span{
    height: 3px !important;
    width: 7px !important;
  }

  aside .aside-flujos__filtros__fechas label{
    padding: 4px;
    width: 100%;
  }

  aside .aside-flujos__filtros__fechas{
    margin: 3px 0px 10px 0px;
  }

  .container-analisis aside .aside-analisis__filtros{
    padding: 10px 0px;
  }

  .container-analisis aside{
    margin: 2rem 2rem 1rem 2rem;
  }

  .container-analisis aside nav{
    padding: 10px 0px;
  }
  
  .container-analisis aside .aside-analisis__filtros .aside-analisis__filtros__area-analisis{
    display: flex;
    align-items: center;
  }

  .container-analisis aside .aside-analisis__filtros .aside-analisis__filtros__area-analisis select{
    margin: 0px 5px 0px 5px;
  }

  .container-analisis .vizContainer {
    height: calc(100vh - 133px);
  }

  .container-flujos aside nav {
    top: 2.7rem;
  }

  .aside-plegado .aside__logo img {
    height: 32px;
    width: auto;
  }

  .aside-plegado {
    max-height: 32px !important;
    max-width: 27px !important;
  }

  .login__container img{
    width: 134px !important;
  }

  .login__container{
    width: 277px !important;
    padding: 4rem 2rem 5rem 2rem;
  }

  .vpo_logo{
    margin-top: 29px;
  }

  .detalle-nodo-clickado__titulo label{
    font-size: 16px !important;
  }

  .detalle-nodo-clickado {
    width: 265px;
  }

  .aside_datos_extra .platafomas_tiendas > div:first-child {
    margin-right: 20px;
  }
}

/******* Logo Vpo Flujos *******/

.logo_vpo_flujos{
  position: absolute;
  bottom: 50px;
  right: 50px;
  z-index: 33;
}

.logo_vpo_flujos img{ 
  width: 75px;
}

.logo_vpo_flujos a{ 
  transition: 0.3s;
}

.logo_vpo_flujos a:hover{ 
  filter: brightness(1.4);
}